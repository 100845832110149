import request from '@/utils/request'

// findCancelCardManagesCarrierAccounts 获取销卡管理中的每个运营商账户
export function findCancelCardManagesCarrierAccounts(params) {
  return request({
    url: `/cancel_card_manages/carrier_accounts`,
    method: 'get',
    params
  })
}

// findCancelCardManagesCarrierAccountsByCarrierType 通过运营商类型获取所有该类型的运营商账户的本月预计销卡数量
export function findCancelCardManagesCarrierAccountsByCarrierType(params) {
  return request({
    url: `/cancel_card_manages/carrier_type/carrier_accounts`,
    method: 'get',
    params
  })
}

// findExpectCancelCardIdByIccidOrPhoneNumber 通过iccid搜索待销卡的卡ID
export function findExpectCancelCardIdByIccidOrPhoneNumber(params) {
  return request({
    url: `/cancel_card_manages/sim_card_id`,
    method: 'get',
    params
  })
}

// findExpectCancelCardListByCarrierAccount 获取销卡管理中运营商账户下的待销卡列表
export function findExpectCancelCardListByCarrierAccount(params, id) {
  return request({
    url: `/cancel_card_manages/carrier_account/${id}/sim_cards`,
    method: 'get',
    params
  })
}

// findExpectCancelCardsCountByCarrierAccount 通过运营商账户id获取本月预计销卡数量
export function findExpectCancelCardsCountByCarrierAccount(id) {
  return request({
    url: `/cancel_card_manages/carrier_account/${id}/sim_cards/count`,
    method: 'get'
  })
}

// findExpectCancelIccidsByCarrierAccountIds 通过运营商账户id获取本月预计销卡数量
export function findExpectCancelIccidsByCarrierAccountIds(params) {
  return request({
    url: `/cancel_card_manages/iccids`,
    method: 'get',
    params
  })
}
// findCancelCardManagesCarrierAccountsByExport 通过导出获取销卡管理运营商账户列表
export function findCancelCardManagesCarrierAccountsByExport(params) {
  return request({
    url: `/cancel_card_manages/export/carrier_accounts`,
    method: 'get',
    params
  })
}

// exportExpectCancelSimCards 导出销卡管理的卡
export function exportExpectCancelSimCards(data) {
  return request({
    url: `/cancel_card_manages/export`,
    method: 'post',
    data
  })
}

// exportExpectCancelSimCardsByCarrierAccount 通过运营商账户导出销卡管理的卡
export function exportExpectCancelSimCardsByCarrierAccount(id, data) {
  return request({
    url: `/cancel_card_manages/carrier_account/${id}/export`,
    method: 'post',
    data
  })
}
