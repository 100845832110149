<template>
  <div>
    <search-carrier-account-expect-cancel-card
      @submit="submitSearch"
    />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="showUnicomCancelModal">联通销卡</a-button>
        <a-button type="primary" @click="showMobileCancelModal">移动销卡</a-button>
        <a-button type="primary" @click="showTelecomCancelModal">电信销卡</a-button>
        <a-button type="primary" @click="showBroadnetCancelModal">广电销卡</a-button>
        <a-button type="primary" @click="showMixCancelModal">融合销卡</a-button>
        <a-button type="primary" @click="showInternationalCancelModal">国际销卡</a-button>
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      row-key="id"
    >
      <div slot="carrier_account_name" slot-scope="carrier_account_name, record">
        <router-link :to="{ name: 'carrier_accounts_expect_cancel_cards', params: { id: record.id }}"> {{ carrier_account_name }} </router-link>
      </div>
    </a-table>

    <!-- 详情模态框 -->
    <show-sim-card
      v-if="isShowSimCardInfoModal"
      :visible.sync="isShowSimCardInfoModal"
      :id="showingId"
    />

    <telecom-cancel-modal
      v-if="isShowTelecomCancelModal"
      :visible.sync="isShowTelecomCancelModal"
      :iccids="iccids"
    />

    <mobile-cancel-modal
      v-if="isShowMobileCancelModal"
      :visible.sync="isShowMobileCancelModal"
      :iccids="iccids"
    />

    <unicom-cancel-modal
      v-if="isShowUnicomCancelModal"
      :visible.sync="isShowUnicomCancelModal"
      :iccids="iccids"
    />

    <broadnet-cancel-modal
      v-if="isShowBroadnetCancelModal"
      :visible.sync="isShowBroadnetCancelModal"
      :iccids="iccids"
    />

    <mix-cancel-modal
      v-if="isShowMixCancelModal"
      :visible.sync="isShowMixCancelModal"
      :iccids="iccids"
    />

    <international-cancel-modal
      v-if="isShowInternationalCancelModal"
      :visible.sync="isShowInternationalCancelModal"
      :iccids="iccids"
    />

    <export-expect-cancel-card-modal
      v-if="isShowExportExpectCancelCardModal"
      :visible.sync="isShowExportExpectCancelCardModal"
      :carrier-account-ids="selectedRowKeys"
    />

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import SearchCarrierAccountExpectCancelCard from '@/views/cancel_card_manages/Search'
import Pagination from '@/components/Pagination'
import {
  findCancelCardManagesCarrierAccounts,
  findExpectCancelCardIdByIccidOrPhoneNumber,
  findExpectCancelIccidsByCarrierAccountIds
} from '@/api/cancel_card_manage'
export default {
  name: 'CancelCardManageIndex',
  components: {
    SearchCarrierAccountExpectCancelCard,
    Pagination,
    ShowSimCard: () => import('@/views/sim_cards/Show'),
    TelecomCancelModal: () => import('@/views/cancel_card_manages/ChinaTelecomCancelModal'),
    MobileCancelModal: () => import('@/views/cancel_card_manages/ChinaMobileCancelModal'),
    UnicomCancelModal: () => import('@/views/cancel_card_manages/ChinaUnicomCancelModal'),
    BroadnetCancelModal: () => import('@/views/cancel_card_manages/ChinaBroadnetCancelModal'),
    MixCancelModal: () => import('@/views/cancel_card_manages/MixCarrierCancelModal'),
    InternationalCancelModal: () => import('@/views/cancel_card_manages/InternationalCarrierCancelModal'),
    ExportExpectCancelCardModal: () => import('@/views/cancel_card_manages/ExportExpectCancelCardModal.vue')
  },
  data() {
    return {
      isShowUnicomCancelModal: false,
      isShowMobileCancelModal: false,
      isShowTelecomCancelModal: false,
      isShowBroadnetCancelModal: false,
      isShowMixCancelModal: false,
      isShowInternationalCancelModal: false,
      isShowSimCardInfoModal: false, // 是否显示卡详情弹窗
      isShowExportExpectCancelCardModal: false,
      data: [],
      loading: false,
      showingId: 0,
      selectedRowKeys: [],
      iccids: [],
      query: {},
      pagination: {
        total_count: 0
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '运营商账户',
          dataIndex: 'carrier_account_name',
          fixed: 'left',
          width: 400,
          scopedSlots: { customRender: 'carrier_account_name' }
        },
        {
          title: '运营商种类',
          dataIndex: 'carrier_type',
          width: 400
        },
        {
          title: '待销卡数',
          dataIndex: 'expect_cancel_card_count',
          width: 400
        }
      ]
    }
  },
  methods: {
    showUnicomCancelModal() {
      this.fetchExpectCancelIccidsByCarrierAccountIds('china_unicom')
    },

    showMobileCancelModal() {
      this.fetchExpectCancelIccidsByCarrierAccountIds('china_mobile')
    },

    showTelecomCancelModal() {
      this.fetchExpectCancelIccidsByCarrierAccountIds('china_telecom')
    },

    showBroadnetCancelModal() {
      this.fetchExpectCancelIccidsByCarrierAccountIds('china_broadnet')
    },

    showMixCancelModal() {
      this.fetchExpectCancelIccidsByCarrierAccountIds('mix_carrier')
    },

    showInternationalCancelModal() {
      this.fetchExpectCancelIccidsByCarrierAccountIds('international_carrier')
    },

    fetchExpectCancelIccidsByCarrierAccountIds(carrierType) {
      findExpectCancelIccidsByCarrierAccountIds(
        { carrier_type: carrierType, carrier_account_ids: this.selectedRowKeys }
      ).then((res) => {
        if (res.code === 0) {
          this.iccids = res.data.iccids
        }

        switch (carrierType) {
          case 'china_telecom':
            this.isShowTelecomCancelModal = true
            break
          case 'china_mobile':
            this.isShowMobileCancelModal = true
            break
          case 'china_unicom':
            this.isShowUnicomCancelModal = true
            break
          case 'china_broadnet':
            this.isShowBroadnetCancelModal = true
            break
          case 'mix_carrier':
            this.isShowMixCancelModal = true
            break
          case 'international_carrier':
            this.isShowInternationalCancelModal = true
            break
        }
      })
    },

    showSimCardInfoModal(id) {
      this.showingId = id
      this.isShowSimCardInfoModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      if (this.query.iccid_or_phone_number === undefined || this.query.iccid_or_phone_number === '') {
        this.fetchData()
      } else {
        this.fetchExpectCancelCardIdByIccidOrPhoneNumber()
      }
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    fetchExpectCancelCardIdByIccidOrPhoneNumber() {
      this.selectedRowKeys = []
      findExpectCancelCardIdByIccidOrPhoneNumber(Object.assign({}, this.query)
      ).then((res) => {
        if (res.code === 0) {
          this.showSimCardInfoModal(res.data.id)
        }
      })
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findCancelCardManagesCarrierAccounts(Object.assign({}, this.query)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    exportData() {
      this.isShowExportExpectCancelCardModal = true
    }
  }
}
</script>
