<template>
  <div>
    <a-form
      class="simiot-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item>
            <a-input
              v-decorator="['iccid_or_phone_number', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入ICCID或SIMID或手机号"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item label="运营商种类">
            <a-select
              v-decorator="['carrier_type']"
              allow-clear
              @change="handleCarrierTypeChange"
            >
              <a-select-option
                v-for="option in carrierTypeOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item label="运营商账户">
            <a-select
              v-decorator="['carrier_account_id']"
              :loading="loadingCarrierAccountOptions"
              allow-clear
              @change="handleCarrierAccountChange"
            >
              <a-select-option
                v-for="option in carrierAccountOptions"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import {
  findCarrierTypeOptions,
  findCarrierAccountOptions
} from '@/api/common_options'
export default {
  name: 'SearchCarrierAccountExpectCancelCard',
  data() {
    return {
      carrierTypeOptions: findCarrierTypeOptions(),
      carrierAccountOptions: [],
      loadingCarrierAccountOptions: false,
      form: this.$form.createForm(this, { name: 'expect_candle_sim_card_search' })
    }
  },
  methods: {

    // 运营商种类变更，加载运营商账户和运营商状态
    handleCarrierTypeChange(carrierType) {
      this.loadingCarrierAccountOptions = true
      // 清空选项
      this.carrierAccountOptions = []
      this.cardStatusOptions = []
      // 清空选项选中的值
      this.form.setFieldsValue({
        carrier_account_id: undefined,
        card_status: undefined
      })

      if (carrierType) {
        findCarrierAccountOptions(carrierType).then(res => {
          if (res.code === 0) {
            this.loadingCarrierAccountOptions = false
            this.carrierAccountOptions = res.data
          }
        })
      }
    },

    // 运营商账户变更，加载运营商状态
    handleCarrierAccountChange(carrierAccountId) {
      this.loadingCardStatusOptions = true
      // 清空选项
      this.cardStatusOptions = []
      // 清空选项选中的值
      this.form.setFieldsValue({
        card_status: undefined
      })
    },

    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    },

    handleReset() {
      // 清空选项
      this.carrierAccountOptions = []
      this.form.resetFields()
      this.$emit('submit', {})
    }

  }
}
</script>
